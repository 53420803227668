var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('pubBoxTit',{attrs:{"title":"电影福利","ltit":"CONVENIENT","borderNone":"1"}}),_vm._m(1),_c('div',[_c('subTitle',{attrs:{"zh-tit":"我们优质的合作院线","en-tit":"CINEMA LINE"}}),_c('div',{staticClass:"yx_box"},[_c('yxBox')],1),_vm._m(2)],1),_c('div',{staticClass:"tic_mai"},[_c('pubBoxTit',{attrs:{"ltit":"TICKET PURCHASE","cakeTit":true,"title":"购票方式"}}),_c('div',{staticClass:"tit_right_btn"},_vm._l((_vm.selArr),function(item,index){return _c('p',{class:{btnActive:_vm.current === index},on:{"mousemove":function($event){return _vm.mouseChange(index)}}},[_vm._v(_vm._s(item))])}),0),(_vm.current == 0)?_c('div',{staticClass:"mai_use"},[_c('pubUse',{attrs:{"stype":"2"}})],1):_vm._e(),(_vm.current == 1)?_c('div',{staticClass:"mai_dh"},[_vm._m(3),_vm._m(4)]):_vm._e(),(_vm.current == 2)?_c('div',{staticClass:"mai_dh last_dh"},[_vm._m(5),_vm._m(6)]):_vm._e()],1),_c('pubFormInput')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":"https://qiniu.youjiamall.cn/yjpc/banner2.png","alt":""}})])]),_c('div',{staticClass:"swiper-pagination"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve_box"},[_c('div',{staticClass:"con_left"},[_c('p',[_c('img',{attrs:{"src":"https://qiniu.youjiamall.cn/dy_card444.png","alt":""}}),_c('img',{attrs:{"src":"https://qiniu.youjiamall.cn/dy_card222.png","alt":""}})]),_c('p',[_c('img',{attrs:{"src":"https://qiniu.youjiamall.cn/dy_card555.png","alt":""}}),_c('img',{attrs:{"src":"https://qiniu.youjiamall.cn/dy_card333.png","alt":""}})])]),_c('div',{staticClass:"con_right"},[_c('p',[_vm._v("线上购票 线下购票")]),_c('p',{staticClass:"rig_content"},[_vm._v("\n          电影福利我们有电影券、电影卡、VIP电影券、VIP电影卡、电影次票5种产品形式，所有电影产品均支持线上购票、线下影院购票、人工客服代购票，可根据企业需求灵活选择相关产品，也可根据企业需求定制观影方案。\n        ")]),_c('img',{attrs:{"src":"https://qiniu.youjiamall.cn/dy_card111.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"btm_box"},[_c('ul',[_c('li',[_c('p',[_vm._v("叠加使用")]),_c('span',[_vm._v("\n                同卡种多张电影卡 "),_c('br'),_vm._v("\n                可叠加使用\n              ")])]),_c('li',[_c('p',[_vm._v("覆盖全国")]),_c('span',[_vm._v("\n                600+城市 "),_c('br'),_vm._v("\n                10000+影院\n              ")])]),_c('li',[_c('p',[_vm._v("使用便捷")]),_c('span',[_vm._v("\n                在线选座\n                 "),_c('br'),_vm._v("\n                实时出票\n              ")])]),_c('li',[_c('p',[_vm._v("多样购票")]),_c('span',[_vm._v("\n                线上购票 线下兑换 "),_c('br'),_vm._v("\n                人工代买\n              ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"use_left"},[_vm._v("\n          用户到线下任意合作影院柜台，通过柜台选择时间、场次、  "),_c('br'),_vm._v("座位，由工作人员直接出票观影。\n        ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://qiniu.youjiamall.cn/yjpc/xxdm.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"use_left"},[_vm._v("\n              用户在可在工作时间拨打客服电话，告知客服人员想要观看的  影院、影片信息，由客服人员代购票，购票成功后将取票码告  知用户，用户到达影院凭取票码取票即可观影。\n            ")]),_c('div',{staticStyle:{"margin-top":"35px"}},[_vm._v("\n              周一至周日：9:00-21:00 "),_c('br'),_vm._v("\n              客服电话：400-700-1314\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://qiniu.youjiamall.cn/yjpc/rgdm.png","alt":""}})])
}]

export { render, staticRenderFns }