<template>
  <div>
    <div class="swiper">
      <div class="swiper-container">
        <div class="swiper-wrapper">

          <div class="swiper-slide">
            <img src="https://qiniu.youjiamall.cn/yjpc/banner2.png" alt="">
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <pubBoxTit title="电影福利" ltit="CONVENIENT" borderNone="1"  ></pubBoxTit>
    <div class="conve_box">
      <div class="con_left">
        <p>
          <img src="https://qiniu.youjiamall.cn/dy_card444.png" alt="">
          <img src="https://qiniu.youjiamall.cn/dy_card222.png" alt="">
        </p>
        <p>
          <img src="https://qiniu.youjiamall.cn/dy_card555.png" alt="">
          <img src="https://qiniu.youjiamall.cn/dy_card333.png" alt="">
        </p>
      </div>

      <div class="con_right">
        <p>线上购票 线下购票</p>
        <p class="rig_content">
          电影福利我们有电影券、电影卡、VIP电影券、VIP电影卡、电影次票5种产品形式，所有电影产品均支持线上购票、线下影院购票、人工客服代购票，可根据企业需求灵活选择相关产品，也可根据企业需求定制观影方案。
        </p>
        <img src="https://qiniu.youjiamall.cn/dy_card111.png" alt="">
      </div>

    </div>
<!--    我们优质的合作院线-->
    <div>
      <subTitle zh-tit="我们优质的合作院线" en-tit="CINEMA LINE"></subTitle>

      <div class="yx_box">
        <yxBox></yxBox>
      </div>
      <div>
        <div class="btm_box">
          <ul>
            <li>
              <p>叠加使用</p>
              <span>
                同卡种多张电影卡 <br>
                可叠加使用
              </span>
            </li>
            <li>
              <p>覆盖全国</p>
              <span>
                600+城市 <br>
                10000+影院
              </span>
            </li>
            <li>
              <p>使用便捷</p>
              <span>
                在线选座
                 <br>
                实时出票
              </span>
            </li>
            <li>
              <p>多样购票</p>
              <span>
                线上购票 线下兑换 <br>
                人工代买
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

<!--    购票方式-->

    <div class="tic_mai">
      <pubBoxTit ltit="TICKET PURCHASE" :cakeTit="true" title="购票方式"></pubBoxTit>
      <div class="tit_right_btn">
        <p  v-for="(item,index) in selArr" :class="{btnActive:current === index}" @mousemove="mouseChange(index)">{{item}}</p>
      </div>
      <div class="mai_use" v-if="current == 0">
        <pubUse stype="2"></pubUse>
      </div>
      <div class="mai_dh" v-if="current == 1">
        <div class="use_left">
          用户到线下任意合作影院柜台，通过柜台选择时间、场次、  <br>座位，由工作人员直接出票观影。
        </div>
        <div>
          <img src="https://qiniu.youjiamall.cn/yjpc/xxdm.png" alt="">
        </div>
      </div>
      <div class="mai_dh last_dh" style="" v-if="current == 2">
          <div style="">
            <div class="use_left">
              用户在可在工作时间拨打客服电话，告知客服人员想要观看的  影院、影片信息，由客服人员代购票，购票成功后将取票码告  知用户，用户到达影院凭取票码取票即可观影。
            </div>
            <div style="margin-top: 35px;">
              周一至周日：9:00-21:00 <br>
              客服电话：400-700-1314
            </div>
          </div>

          <div>
            <img src="https://qiniu.youjiamall.cn/yjpc/rgdm.png" alt="">
          </div>
      </div>
    </div>
    <pubFormInput></pubFormInput>
  </div>
</template>

<script>
import pubBoxTit from "@/components/pubBoxTit";
import pubUse from "@/components/pubUse";
import pubFormInput from "@/components/pubFormInput";
import subTitle from "@/components/subTitle";
import yxBox from "@/components/yxBox";
export default {
  name: "Welfare",
  metaInfo () {
    return {
      title:"优加惠品",
      meta: [{
        name: 'keyWords',
        content: '优加惠品,福利,年节慰问品,电影,蛋糕福利'
      },
        {
          name: 'description',
          content: '优加惠品,优加,福利,慰问品,年节慰问品,电影,提货券,蛋糕卡,电影卡,生日卡,生日券,蛋糕券,全福卡,全福券,蛋糕,电影福利,蛋糕福利,元宵节慰问品,龙抬头慰问品,端午慰问品节,七夕节慰问品,三八妇女节,五一劳动节,中秋节慰问品,重阳节慰问品,下元节慰问品,冬至节慰问品,腊八节慰问品,小年慰问品,除夕慰问品,春节慰问品,元宵节,龙抬头,端午节,七夕节,中秋节,重阳节,三八妇女节,五一劳动节,下元节,冬至节,腊八节,小年,员工福利,员工体检,企业团险,员工团建活动,夏送清凉,冬送温暖'
        }],
      link: [{ // set link
        rel: 'asstes',
        href: 'https://assets-cdn.github.com/'
      }]
    }
  },
  components:{pubBoxTit,pubFormInput,pubUse,subTitle,yxBox},
  data() {
      return {
        selArr:["线上购票","线下兑换","人工代买"],
        current:0,
      }
  },
  methods:{
    mouseChange(index) {
      this.current = index;
    }
  }
}
</script>

<style scoped>
.swiper img{
}
.conve_box {
  padding-left: 120px;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  padding-bottom: 100px;
  background: url("https://qiniu.youjiamall.cn/yjpc/sucai.png")no-repeat  100% 0;
  background-size: 55% auto;
}
.con_left p:nth-child(1) {
  margin-bottom: 47px;
}
.con_left p img:nth-child(1){
  width: 352px;
  height: 175px;
  margin-right: 29px;
}
.con_left p img:nth-child(2){
  width: 242px;
  height: 153px;
  border-radius: 8px;
}
.con_right {

  padding-top: 15px;
  box-sizing: inherit;
  margin-left: 74px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.rig_content {
  color: #666;
  line-height: 24px;
  margin-top: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 390px;
  margin-bottom: 74px;
}
.con_right img {
  width: 353px;
  height: 176px;
}
.mai_box {
  /*padding: 40px 147px 100px 145px;*/
  box-sizing: border-box;
  padding: 40px 120px 100px 120px;

  /*background: #E9EFF4;*/
}

.tic_mai {
  position: relative;
  box-sizing: border-box;
  background: #E9EFF4;
  /*padding-bottom: 120px;*/
}
.mai_use {
  padding: 0 100px 100px;
  box-sizing: border-box;
}
.btm_box {
  width: 100%;
  height: 360px;
  padding: 0 72px;
  box-sizing: border-box;
  background: url("https://qiniu.youjiamall.cn/yjpc/welbg.png")no-repeat;
  background-size: 100% 100%;
  display: flex;
  color: #fff;
}
.btm_box ul {
  margin: auto;
  width: 100%;
  height: 135px;
  background: url("https://lanhu.oss-cn-beijing.aliyuncs.com/pszwsbl1dx0ooqagu5rcputm8jxd2vln80ffde454-a83b-405a-909c-5d998d1c1334")no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
}
.btm_box li {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFEBB3;
  width: 100%;
  text-align: center;
}
.btm_box p {
  margin-bottom: 15px;
}
.btm_box li span{
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFEBB3;
}
.yx_box {
  margin-top: 13px;
  /*padding: 0 182px 0 179px;*/
  box-sizing: border-box;
}

.tit_right_btn {
  position: absolute;
  right: 50px;
  top: 50px;
  transform: translateY(-50%);
  display: flex;
}
.tit_right_btn p{
  cursor: pointer;
  width: 106px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666;
  margin-right: 10px;
}
.tit_right_btn .btnActive {
  background: #007858;
  color: #fff;
}
.mai_dh img {
  width: 450px;
  height: auto;
}
.mai_dh {
  height: 350px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 60px 120px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
}

.last_dh {
  justify-content: space-around;
  align-items: center;
  line-height: 30px;
}
.last_dh img {
  width: 320px;
  height: auto;
  /*transform: scale(0.9);*/
}
.use_left {
  width: 500px;
  margin-right: 30px;
  line-height: 30px;
}
</style>
