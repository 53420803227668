<template>
  <div class="wrap">
    <div class="yx_wrap">
      <p class="yx_img" style="overflow:hidden;display:flex;    padding-left: 6px;
    box-sizing: border-box" >
        <img src="https://qiniu.youjiamall.cn/yjpc/yy_9.jpg" style="height: auto;margin: auto;width: 100%;transform: scale(1.2)" alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>

    <div class="yx_wrap">
      <p class="yx_img" style="display:flex;
;">
        <img src="https://qiniu.youjiamall.cn/yjpc/yy1.png" style="transform: scale(0.75);
    width: 100%;
    height: auto;
    margin: auto;
" alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>

    <div class="yx_wrap">
      <p class="yx_img" style="display:flex;">
        <img src="https://qiniu.youjiamall.cn/yjpc/yy_2.jpg" style="transform: scale(0.6);width: 100%;height: 100%;margin: auto;" alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>

    <div class="yx_wrap">
      <p class="yx_img">
        <img src="https://qiniu.youjiamall.cn/yy_3.png" style="transform: scale(0.6)" alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>

    <div class="yx_wrap">
      <p class="yx_img" style="display:flex;">
        <img src="https://qiniu.youjiamall.cn/yjpc/yy_8.png" style="transform: scale(0.85);width: 100%;height: auto;margin: auto;" alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>

    <div class="yx_wrap">
      <p class="yx_img">
        <img src="https://qiniu.youjiamall.cn/yjpc/yy_5.jpg" style="transform: scale(0.7); width: 100%;height: auto;" alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>

    <div class="yx_wrap">
      <p class="yx_img">
        <img src="https://qiniu.youjiamall.cn/yjpc/yy_6.png" style="transform: scale(0.8)" alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>

    <div class="yx_wrap">
      <p class="yx_img" style="display:flex;">
        <img style="transform: scale(0.75);height:auto; width: 100%;margin: auto;" src="https://qiniu.youjiamall.cn/yjpc/yy7.jpg"  alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>

    <div class="yx_wrap">
      <p class="yx_img" style="display:flex;">
        <img src="https://qiniu.youjiamall.cn/yjpc/yy4.jpg" style="width: 100%;height: auto;margin: auto; transform: scale(.75)" alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>

    <div class="yx_wrap">
      <p class="yx_img" style="position: relative">
        <img src="https://qiniu.youjiamall.cn/yjpc/yy_12.png" style="transform: scale(0.3);width: auto;position: absolute;right: -58%;" alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>

    <div class="yx_wrap">
      <p class="yx_img">
        <img src="https://qiniu.youjiamall.cn/yjpc/yy_11.jpg" style="transform: scale(0.7)" alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>

    <div class="yx_wrap">
      <p class="yx_img" style="overflow:hidden;padding-bottom: 10px; box-sizing: border-box">
        <img src="https://qiniu.youjiamall.cn/yjpc/yy_112.png" style="transform: scale(0.65);" alt="">
      </p>
      <div class="x_box">
        <p class="yx_yuan"></p>
        <div class="yx_xian"></div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "yxBox"
}
</script>

<style scoped>
.wrap {
  display:flex;position:relative;
  height: 600px;
}

.yx_xian {
  width: 1px;
  height: 215px;
  background: #7FBBAB;
  opacity: 0.5;
  margin-left: 5.5px;
}
.yx_yuan {
  width: 13px;
  height: 13px;
  background: #007858;
  opacity: 0.5;
  border-radius: 50%;
}

.yx_img {
  overflow: hidden;
  width: 105px;
  height: 105px;
  border-radius: 100%;
  box-shadow: 0px 5px 22px 0px rgba(0, 120, 88, 0.08);
  margin-bottom: 53px;
}
.yx_img img {
  width: 100%;
  height: 100%;
  /*transform: scale(0.4);*/
}
.yx_wrap {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 105px;
}
.yx_wrap:nth-child(1) {
  left: 139px;
}
.yx_wrap:nth-child(2) {
  left: 237px;
}
.yx_wrap:nth-child(3) {
  left: 326px;
}
.yx_wrap:nth-child(4) {
  left: 400px;
}
.yx_wrap:nth-child(5) {
  left: 480px;
}
.yx_wrap:nth-child(6) {
  left: 547px;
}
.yx_wrap:nth-child(7) {
  left: 645px;
}
.yx_wrap:nth-child(8) {
  left: 735px;
}
.yx_wrap:nth-child(9) {
  left: 842px;
}
.yx_wrap:nth-child(10) {
  right: 386px;
}
.yx_wrap:nth-child(11) {
  right: 286px;
}
.yx_wrap:nth-child(12) {
  right: 184px;
}


.yx_wrap:nth-child(2) .yx_img{
  width: 161px;
  height: 161px;
  margin-bottom: 29px;
}
.yx_wrap:nth-child(2) .yx_xian{
  height: 420px;
}



.yx_wrap:nth-child(3) .yx_img{
  width: 105px;
  height: 105px;
  margin-bottom: 32px;
}
.yx_wrap:nth-child(3) .yx_xian{
  height: 298px;
}


.yx_wrap:nth-child(4) .yx_img{
  width: 105px;
  height: 105px;
  margin-bottom: 52px;
}
.yx_wrap:nth-child(4) .yx_xian{
  height: 132px;
}


.yx_wrap:nth-child(5) .yx_img{
   margin-bottom: 28px;
 }
.yx_wrap:nth-child(5) .yx_xian{
  height: 268px;
}

.yx_wrap:nth-child(6) .yx_img{
  width: 130px;
  height: 130px;
  margin-bottom: 30px;
}
.yx_wrap:nth-child(6) .yx_xian{
  height: 415px;
}

.yx_wrap:nth-child(7) .yx_img{
  width: 121px;
  height: 121px;
  margin-bottom: 50px;
}
.yx_wrap:nth-child(7) .yx_xian{
  height: 197px;
}


.yx_wrap:nth-child(8) .yx_img{
  width: 128px;
  height: 128px;
  margin-bottom: 35px;
}
.yx_wrap:nth-child(8) .yx_xian{
  height: 342px;
}

.yx_wrap:nth-child(9) .yx_img{
  width: 136px;
  height: 136px;
  margin-bottom: 48px;
}
.yx_wrap:nth-child(9) .yx_xian{
  height: 138px;
}

.yx_wrap:nth-child(10) .yx_img{
  margin-bottom: 33px;
}
.yx_wrap:nth-child(10) .yx_xian{
  height: 334px;
}


.yx_wrap:nth-child(11) .yx_img{
  width: 145px;
  height: 145px;
  margin-bottom: 31px;
}
.yx_wrap:nth-child(11) .yx_xian{
  height: 433px;
}

.yx_wrap:nth-child(12) .yx_img{
  width: 118px;
  height: 118px;
  margin-bottom: 47px;
}
.yx_wrap:nth-child(12) .yx_xian{
  height: 245px;
}

.yx_wrap:nth-child(13) .yx_img{
  margin-bottom: 29px;
}
.yx_wrap:nth-child(13) .yx_xian{
  height: 375px;
}

.yx_wrap:nth-child(14) .yx_img{
  margin-bottom: 42px;
}
.yx_wrap:nth-child(14) .yx_xian{
  height: 126px;
}
</style>
